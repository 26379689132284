/* Constants */
:root {
  --cool-gray: #e8e8e8;
  --rich-black: #323232;
  --brand-dark-1: #2da073;
  --brand-dark-2: #288e66;
  --brand-dark-3: #237c59;
  --brandcolor: #32b280;
  --white: #ffffff;
  --dark-gray-default: #505050;
  --dark-gray: #6e6e6e;
  --light-gray: #b3b3b3;
  --blue-compliment-2: #018de5;
  --blue-compliment-1: #0197f6;
  --blue-compliment-4: #0078c2;
  --blue-compliment-3: #0082d3;
  --red-compliment-1: #ef767a;
  --red-compliment-2: #e36367;
  --yellow-compliment-3: #dd8510;
  --yellow-compliment-4: #cc7400;
  --yellow-compliment-2: #ee9520;
  --red-compliment-4: #cc3d42;
  --yellow-compliment-1: #ffa630;
  --red-compliment-3: #d85055;
  --orange-compliment-1: #f4743b;
  --orange-compliment-2: #e36730;
  --orange-compliment-3: #d35a26;
  --orange-compliment-4: #c24d1b;
  --greyish-brown: #505050;
  --breakpoint-desktop: 1366px;
  --breakpoint-mobile: 576px;
}

.f--bold {
  font-weight: bold;
}

.f--italic {
  font-style: italic;
}

.f-underline {
  text-decoration: underline;
}

.f--bold-onhover:hover {
  font-weight: bold;
  transition: font-weight 0.15s;
}

.f--italic-onhover:hover {
  font-style: italic;
  transition: 0.15s;
}

.f--underline-onhover:hover {
  text-decoration: underline;
  transition: 0.15s;
}

/* Font-size utils */
.f--1 {
  font-size: 1px !important;
}

.f--2 {
  font-size: 2px !important;
}

.f--3 {
  font-size: 3px !important;
}

.f--4 {
  font-size: 4px !important;
}

.f--5 {
  font-size: 5px !important;
}

.f--6 {
  font-size: 6px !important;
}

.f--7 {
  font-size: 7px !important;
}

.f--8 {
  font-size: 8px !important;
}

.f--9 {
  font-size: 9px !important;
}

.f--10 {
  font-size: 10px !important;
}

.f--11 {
  font-size: 11px !important;
}

.f--12 {
  font-size: 12px !important;
}

.f--13 {
  font-size: 13px !important;
}

.f--14 {
  font-size: 14px !important;
}

.f--15 {
  font-size: 15px !important;
}

.f--16 {
  font-size: 16px !important;
}

.f--17 {
  font-size: 17px !important;
}

.f--18 {
  font-size: 18px !important;
}

.f--19 {
  font-size: 19px !important;
}

.f--20 {
  font-size: 20px !important;
}

.f--21 {
  font-size: 21px !important;
}

.f--22 {
  font-size: 22px !important;
}

.f--23 {
  font-size: 23px !important;
}

.f--24 {
  font-size: 24px !important;
}

.f--25 {
  font-size: 25px !important;
}

.f--26 {
  font-size: 26px !important;
}

.f--27 {
  font-size: 27px !important;
}

.f--28 {
  font-size: 28px !important;
}

.f--29 {
  font-size: 29px !important;
}

.f--30 {
  font-size: 30px !important;
}

.f--31 {
  font-size: 31px !important;
}

.f--32 {
  font-size: 32px !important;
}

.f--33 {
  font-size: 33px !important;
}

.f--34 {
  font-size: 34px !important;
}

.f--35 {
  font-size: 35px !important;
}

.f--36 {
  font-size: 36px !important;
}

.f--37 {
  font-size: 37px !important;
}

.f--38 {
  font-size: 38px !important;
}

.f--39 {
  font-size: 39px !important;
}

.f--40 {
  font-size: 40px !important;
}

.f--41 {
  font-size: 41px !important;
}

.f--42 {
  font-size: 42px !important;
}

.f--43 {
  font-size: 43px !important;
}

.f--44 {
  font-size: 44px !important;
}

.f--45 {
  font-size: 45px !important;
}

.f--46 {
  font-size: 46px !important;
}

.f--47 {
  font-size: 47px !important;
}

.f--48 {
  font-size: 48px !important;
}

.f--49 {
  font-size: 49px !important;
}

.f--50 {
  font-size: 50px !important;
}

.f--51 {
  font-size: 51px !important;
}

.f--52 {
  font-size: 52px !important;
}

.f--53 {
  font-size: 53px !important;
}

.f--54 {
  font-size: 54px !important;
}

.f--55 {
  font-size: 55px !important;
}

.f--56 {
  font-size: 56px !important;
}

.f--57 {
  font-size: 57px !important;
}

.f--58 {
  font-size: 58px !important;
}

.f--59 {
  font-size: 59px !important;
}

.f--60 {
  font-size: 60px !important;
}

.f--61 {
  font-size: 61px !important;
}

.f--62 {
  font-size: 62px !important;
}

.f--63 {
  font-size: 63px !important;
}

.f--64 {
  font-size: 64px !important;
}

.f--65 {
  font-size: 65px !important;
}

.f--66 {
  font-size: 66px !important;
}

.f--67 {
  font-size: 67px !important;
}

.f--68 {
  font-size: 68px !important;
}

.f--69 {
  font-size: 69px !important;
}

.f--70 {
  font-size: 70px !important;
}

.f--71 {
  font-size: 71px !important;
}

.f--72 {
  font-size: 72px !important;
}
/* End of font-size utils */

/* Font weight utils */
.f--weight-100 {
  font-weight: 100 !important;
}

.f--weight-200 {
  font-weight: 200 !important;
}

.f--weight-300 {
  font-weight: 300 !important;
}

.f--weight-400 {
  font-weight: 400 !important;
}

.f--weight-500 {
  font-weight: 500 !important;
}

.f--weight-600 {
  font-weight: 600 !important;
}

.f--weight-700 {
  font-weight: 700 !important;
}

.f--weight-800 {
  font-weight: 800 !important;
}

.f--weight-900 {
  font-weight: 900 !important;
}
/* End of font weight utils */

/* General Styles */
.f-h1,
h1 {
  font-size: 56px !important;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: -1.12px;
  color: var(--dark-gray-default);
}

.f-h2,
h2 {
  font-size: 42px !important;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark-gray-default);
}

.f-h3,
h3 {
  font-size: 32px !important;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: var(--dark-gray-default);
}

.f-h4,
h4 {
  font-size: 22px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark-gray-default);
}

.f-h1-mweb {
  font-size: 20px !important;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: var(--dark-gray-default);
}

.f-body-large {
  font-size: 20px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark-gray-default);
}

.f-h5,
h5 {
  font-size: 18px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: var(--dark-gray-default);
}

.f-h6,
h6 {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--dark-gray-default);
}

.f-body-large {
  font-size: 20px;
  line-height: 1.5;
  font-weight: normal;
  color: var(--dark-gray-default);
}

.f-body-default {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark-gray-default);
}

.f-body-alt {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: var(--dark-gray-default);
}

/* .f-body-small {
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--dark-gray-default);
} */

.f-body-small {
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.66px;
  color: var(--dark-gray-default);
}

.f-subheading-bold {
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: 0.66px;
  color: var(--dark-gray-default);
}

.f-subheading-regular {
  font-size: 11px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.66px;
  color: var(--dark-gray-default);
}

/* Medium Screen Styles */
@media screen and (min-width: 577px) {
  .f-md-h1 {
    font-size: 56px !important;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -1.12px;
    color: var(--dark-gray-default);
  }

  .f-md-h2 {
    font-size: 42px !important;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-md-h3 {
    font-size: 32px !important;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-md-h4 {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-md-h1-mweb {
    font-size: 20px !important;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-md-body-large {
    font-size: 20px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-md-h5 {
    font-size: 18px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    font-family: CircularStd;
    line-height: 1.28;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-md-h6 {
    font-size: 16px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    font-family: CircularStd;
    color: var(--dark-gray-default);
  }

  .f-md-body-large {
    font-size: 20px !important;
    line-height: 1.5;
    font-weight: normal;
    color: var(--dark-gray-default);
  }

  .f-md-body-default {
    font-size: 16px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-family: CircularStd;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-md-body-alt {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    font-family: CircularStd;
    line-height: 1.57;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  /* .f-md-body-small {
    font-size: 12px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  } */

  .f-md-body-small {
    font-size: 11px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0.66px;
    color: var(--dark-gray-default);
  }
}
/* End of Medium Screen Styles */

/* Small Screen Styles */
@media screen and (max-width: 576px) {
  .f-sm-h1 {
    font-size: 56px !important;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -1.12px;
    color: var(--dark-gray-default);
  }

  .f-sm-h2 {
    font-size: 42px !important;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-sm-h3 {
    font-size: 32px !important;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-sm-h4 {
    font-size: 22px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-sm-h1-mweb {
    font-size: 20px !important;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-sm-body-large {
    font-size: 20px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-sm-h5 {
    font-size: 18px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-sm-h6 {
    font-size: 16px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-sm-body-large {
    font-size: 20px !important;
    line-height: 1.5;
    font-weight: normal;
    color: var(--dark-gray-default);
  }

  .f-sm-body-default {
    font-size: 16px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-sm-body-alt {
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-sm-alt-small {
    font-size: 12px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--dark-gray-default);
  }

  .f-sm-body-small {
    font-size: 11px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0.66px;
    color: var(--dark-gray-default);
  }

  .f-sm-footer-small {
    font-size: 5px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 0.66px;
    color: var(--dark-gray-default);
  }

  .f-mweb-h1 {
    font-weight: 900;
    font-size: 32px !important;
    line-height: 1.13;
    letter-spacing: -0.64px;
    color: var(--dark-gray-default);
  }

  .f-mweb-h2 {
    font-weight: 900;
    font-size: 20px !important;
    line-height: 1.2;
    letter-spacing: -0.4px;
    color: var(--dark-gray-default);
  }

  .f-mweb-h3 {
    font-weight: 700;
    font-size: 18px !important;
    line-height: 1.11;
    letter-spacing: -0.18px;
    color: var(--dark-gray-default);
  }

  .f-mweb-h4 {
    font-size: 16px !important;
    font-weight: 700;
    line-height: 1.19;
    color: var(--dark-gray-default);
  }

  .f-mweb-h5 {
    font-size: 14px !important;
    font-weight: 700;
    line-height: 1.14;
    color: var(--dark-gray-default);
  }

  .f-mweb-h6 {
    font-size: 12px !important;
    font-weight: 700;
    line-height: 1.25;
    color: var(--dark-gray-default);
  }

  .f-mweb-body-large {
    font-size: 18px !important;
    font-weight: normal;
    line-height: 1.56;
    color: var(--dark-gray);
  }

  .f-mweb-body-medium {
    font-size: 16px !important;
    font-weight: normal;
    line-height: 1.5;
    color: var(--dark-gray);
  }

  .f-mweb-body-default {
    font-size: 14px !important;
    font-weight: normal;
    line-height: 1.43;
    color: var(--dark-gray);
  }

  .f-mweb-body-small {
    font-size: 12px !important;
    font-weight: normal;
    line-height: 1.5;
    color: var(--dark-gray);
  }

  .f-mweb-subhead-bold {
    font-size: 11px !important;
    font-weight: 700;
    line-height: 1.27;
    color: var(--dark-gray-default);
    letter-spacing: 0.66px;
  }

  .f-mweb-subhead-regular {
    font-size: 11px !important;
    font-weight: normal;
    line-height: 1.36;
    color: var(--dark-gray-default);
    letter-spacing: 0.66px;
  }
}

/* Font color utils */
.f--cool-gray {
  color: var(--cool-gray);
}

.f--rich-black {
  color: var(--rich-black);
}

.f--brand-color {
  color: var(--brandcolor);
}

.f--dark-gray {
  color: var(--dark-gray);
}

.f--light-gray {
  color: var(--light-gray);
}

.f--white {
  color: var(--white);
}

.f--dark-gray-default {
  color: var(--dark-gray-default);
}
.f--red-compliment-1 {
  color: var(--red-compliment-1);
}
.f--orange-compliment-1 {
  color: var(--orange-compliment-1);
}
.f--greyish-brown {
  color: var(--greyish-brown);
}
/* End of font color utils */

/* Hover Font Styles */

.f--brand-dark-onhover:hover {
  color: #2da173 !important;
  transition: 0.15s color;
}

.f--cool-gray-onhover:hover {
  color: var(--cool-gray) !important;
  transition: 0.15s color;
}

.f--rich-black-onhover:hover {
  color: var(--dark-gray-default) !important;
  transition: 0.15s color;
}

.f--brand-color-onhover:hover {
  color: var(--brandcolor) !important;
  transition: 0.15s color;
}

.f--dark-gray-onhover:hover {
  color: var(--dark-gray) !important;
  transition: 0.15s color;
}

.f--light-gray-onhover:hover {
  color: var(--light-gray) !important;
  transition: 0.15s color;
}

.f--white-onhover:hover {
  color: var(--white) !important;
  transition: 0.15s color;
}

.f--dark-gray-default-onhover:hover {
  color: var(--dark-gray-default) !important;
  transition: 0.15s color;
}

.f--red-compliment-1-onhover:hover {
  color: var(--red-compliment-1) !important;
  transition: 0.15s color;
}

.f--orange-compliment-1-onhover:hover {
  color: var(--orange-compliment-1) !important;
  transition: 0.15s color;
}

@media (max-width: 576px) {
  .f--sm-brand-color {
    color: var(--brandcolor);
  }
  .f--sm-cool-gray {
    color: var(--cool-gray);
  }

  .f--sm-rich-black {
    color: var(--dark-gray-default);
  }

  .f--sm-dark-gray {
    color: var(--dark-gray);
  }

  .f--sm-light-gray {
    color: var(--light-gray);
  }

  .f--sm-white {
    color: var(--white);
  }
}
