.resultPage-dataTable {
  background-color: #ffffff;
}

.resultPage-sideNav {
  width: 400px;
  height: 400px;
  position: fixed;
  z-index: 1;
  top: 120px;
  left: 50px;
  background: #ffffff;
  overflow-x: hidden;
  padding: 8px;
  border-radius: 10px;
}

.resultPage-sideNav-row {
  margin-top: 10px;
}

.resultPage-sideNav-lastRow {
  margin-top: 30px;
}

.resultPage-sideNav-lastRow-button {
  margin-right: 10px;
  width: 100px;
}
