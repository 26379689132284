/* Font Face */

@font-face {
  font-family: 'CircularStd';
  src: url(./assets/fonts/CircularStd-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'CircularStd';
  src: url(./assets/fonts/CircularStd-Black.otf) format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'CircularStd';
  src: url(./assets/fonts/CircularStd-Bold.otf) format('opentype');
  font-weight: 600;
}

body {
  font-family: 'CircularStd' !important;
  background-color: #f7f8fa !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.drop-shadow {
  -webkit-box-shadow: -2px 10px 29px -5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -2px 10px 29px -5px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 10px 29px -5px rgba(0, 0, 0, 0.2);
}

/* General style */
.progress {
  height: 5px !important;
  border: none;
}

.progress .progress-bar {
  background-color: #32b280 !important;
}

.outline-0 {
  outline: 0 !important;
}

/* Navbar Styles */
.global-nav {
  width: 100%;
  height: 56px;
  box-shadow: 0 20px 40px 0 rgba(102, 102, 102, 0.08);
  background-color: #ffffff;
}

.global-nav .container-fluid {
  padding: 0 0 0 56px;
}

.nav-root {
  display: flex;
  align-items: center;
  height: 56px;
}

.navbar-green-line {
  height: 3px;
  background: rgb(50, 178, 128);
  width: 100%;
}
.navbar-button-tab {
  height: 100%;
  width: 70px !important;
  background-color: #32b280;
  margin-top: -2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.navbar-button-tab:hover {
  cursor: pointer;
}

.overlay {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 56px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.overlay.show-navbar {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background: #020202;
  background: rgba(2, 2, 2, 0.7);
  z-index: 3;
  visibility: visible;
  opacity: 1;
}

.fade-in {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s linear 0s, opacity 300ms, height 300ms;
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0ms, opacity 300ms, height 300ms;
}

.container-nav {
  min-width: 333px;
  right: 10px;
  top: 70px;
  position: absolute;
  background-color: white;
  border-radius: 6px;
  z-index: 5;
}

.profile-nav-border-bottom {
  border-bottom: 1px solid rgba(112, 112, 112, 0.16);
}
.container-nav::before {
  content: '';
  position: absolute;
  left: 90% !important;
  bottom: 100%;
  border: solid transparent;
  border-bottom-color: #fff;
  border-width: 8px;
}

.container-nav p,
.container-nav h6 {
  color: #505050;
}

.user-profile-icon {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.test-navbar {
  height: 56px;
  border-right: 1px solid #dee2e6 !important;
}

.test-navbar img {
  margin-top: 7%;
  margin-right: 48px;
}

.test-navbar-title {
  margin-left: 16px;
  font-weight: bold;
  margin-top: 4.5%;
  font-size: 16px;
}

.class-content {
  min-width: 25vw;
  height: 100%;
  height: 45px;
}

.class-progress {
  display: flex;
  flex-direction: column;
  border-left: 2px solid #f7f8fa;
  border-right: 2px solid #f7f8fa;
}

/* Success Modal */
.pwdk-cross-button {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  right: 5px;
}

.pwdk-cross-button:hover {
  cursor: pointer;
}

.success-modal {
  width: 560px !important;
  border-radius: 6px !important;
}

.success-modal-header {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: #505050;
}

.success-modal-text {
  font-size: 14px;
  text-align: center;
  color: #505050;
  padding: 0 4em;
}

/* TEST CARD Style */

.card.title-quiz {
  padding: 16px 32px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 0px;
}

.card.content-quiz {
  border: 0px;
}

.content-quiz {
  padding-top: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;

  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.content-quiz .card {
  border: 0px;
  border-radius: 0px;
}

.test-problem-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding-bottom: 24px;
}

.content-quiz .question-style {
  padding-top: 32px;
  padding-left: 32px;
  padding-right: 80px;
}

.content-quiz .answer-style {
  padding: 32px 32px 67px;
}

.content-quiz .test-button-container {
  margin-top: 100px;
}

.content-quiz
  .test-button-container
  .test-button-pagination
  .pwdk-btn-medium
  span {
  font-size: 14px;
}

.content-quiz .test-button-container .test-button-pagination .pwdk-btn-medium {
  padding: 14px 17px;
}

/* Belum dipake */

.content-quiz .answer-component {
  cursor: pointer;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  display: block;
  width: 100%;
  padding: 15px 16px 15px 16px;
  border-radius: 6px;
  border: 1px solid var(--light-gray);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: var(--dark-gray-default);
  line-height: 1.5;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
  position: relative;
}

.answer-component.selected-answer {
  border: solid 2px var(--brandcolor);
  color: var(--brandcolor);
  font-weight: bold;
}

.answer-component.selected-answer.btn-secondary {
  color: var(--brandcolor);
}

.answer-component.btn-secondary {
  background-color: transparent !important;
  color: var(--dark-gray-default);
  outline: none !important;
  outline-offset: none !important;
}

.answer-component.btn-secondary:focus {
  color: var(--brandcolor) !important;
  box-shadow: none !important;
}

.answer-component.selected-answer.btn-secondary:focus,
.answer-component.selected-answer.btn-secondary:active,
.answer-component.selected-answer.btn-secondary:visited,
.answer-component.selected-answer.btn-secondary:hover,
.answer-component.selected-answer.btn-secondary:target {
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
  border: solid 2px var(--brandcolor);
}

.answer-component.btn-secondary:hover {
  border: solid 1px var(--brandcolor);
  color: var(--brandcolor);
}
/* Home Page Styles */

.quiz-category {
  font-size: 11px;
  font-weight: 900;
  color: #32b280;
  text-align: left;
  margin-bottom: 1em;
}

.quiz-title {
  font-size: 24px;
  font-weight: bold;
  color: var(--greyish-brown);
  text-align: left;
  margin-bottom: 0.4em;
}

.quiz-description {
  font-size: 16px;
  color: var(--greyish-brown);
  text-align: left;
  margin-bottom: 2.5em;
}

.welcome-text {
  font-size: 24px;
  color: var(--greyish-brown);
  text-align: left;
  margin: 1.5em 0;
  padding: 0 15px;
  font-weight: bold;
}
