/* Constants */
:root {
  --cool-gray: #e8e8e8;
  --rich-black: #323232;
  --brand-dark-1: #2da073;
  --brand-dark-2: #288e66;
  --brand-dark-3: #237c59;
  --brandcolor: #32b280;
  --white: #ffffff;
  --dark-gray-default: #505050;
  --dark-gray: #6e6e6e;
  --light-gray: #b3b3b3;
  --blue-compliment-2: #018de5;
  --blue-compliment-1: #0197f6;
  --blue-compliment-4: #0078c2;
  --blue-compliment-3: #0082d3;
  --red-compliment-1: #ef767a;
  --red-compliment-2: #e36367;
  --yellow-compliment-3: #dd8510;
  --yellow-compliment-4: #cc7400;
  --yellow-compliment-2: #ee9520;
  --red-compliment-4: #cc3d42;
  --yellow-compliment-1: #ffa630;
  --red-compliment-3: #d85055;
  --orange-compliment-1: #f4743b;
  --orange-compliment-2: #e36730;
  --orange-compliment-3: #d35a26;
  --orange-compliment-4: #c24d1b;
  --breakpoint-desktop: 1366px;
  --breakpoint-mobile: 576px;
}

/* General Styles */
.pwdk-btn {
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  line-height: 1.5;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: inline-block;
  background: white;
}

.pwdk-nude-btn {
  color: #32b280;
  font-family: CircularStd;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: normal;
  transition: 0.15s color;
}

@media (max-width: 576px) {
  .pwdk-nude-button {
    font-size: 14px;
  }
}

.pwdk-nude-btn:hover {
  text-decoration: underline;
  color: #2da173;
  cursor: pointer;
}

.pwdk-btn:hover,
.pwdk-btn:focus {
  text-decoration: none;
  cursor: pointer;
}

.pwdk-btn-mini {
  font-size: 10px;
  padding: 5px 16px;
}

.pwdk-btn-small {
  font-size: 12px;
  padding: 6px 16px;
}

.pwdk-btn-medium {
  font-size: 14px;
  padding: 7px 24px;
}

.pwdk-btn-large {
  font-size: 16px;
  padding: 10px 32px;
}

.pwdk-btn-huge {
  font-size: 18px;
  padding: 13px 32px;
}

.pwdk-btn-alt.pwdk-btn-mini:active,
.pwdk-btn-alt.pwdk-btn-mini:hover {
  padding: 4px 16px;
}

.pwdk-btn-alt.pwdk-btn-small:active,
.pwdk-btn-alt.pwdk-btn-small:hover {
  padding: 5px 16px;
}
.pwdk-btn-alt.pwdk-btn-medium:active,
.pwdk-btn-alt.pwdk-btn-medium:hover {
  padding: 6px 24px;
}

.pwdk-btn-alt.pwdk-btn-large:active,
.pwdk-btn-alt.pwdk-btn-large:hover {
  padding: 9px 32px;
}

.pwdk-btn-alt.pwdk-btn-huge:active,
.pwdk-btn-alt.pwdk-btn-huge:hover {
  padding: 12px 32px;
}

.pwdk-btn-primary {
  color: var(--white) !important;
  background-color: var(--brandcolor);
  border-color: var(--brandcolor);
}

.pwdk-btn-primary:hover {
  background-color: var(--brand-dark-1);
  border-color: var(--brand-dark-1);
}

.pwdk-btn-primary:active {
  background-color: var(--brand-dark-3);
  border-color: var(--brand-dark-1);
}

.pwdk-btn-disabled {
  color: var(--white) !important;
  background-color: var(--cool-gray);
  border-color: var(--cool-gray);
  cursor: not-allowed !important;
}

.pwdk-btn-disabled:hover {
  color: var(--white) !important;
  background-color: var(--cool-gray);
  border-color: var(--cool-gray);
  cursor: not-allowed !important;
}

.pwdk-btn-nude {
  padding: 0 !important;
  color: var(--light-gray);
  border: none !important;
}

.pwdk-btn-nude:hover,
.pwdk-btn-nude:active {
  color: var(--brandcolor);
}

.pwdk-btn-ghost {
  border-color: var(--light-gray);
  color: var(--light-gray);
  border: 2px solid var(--light-gray);
}

.pwdk-btn-ghost:active,
.pwdk-btn-ghost:hover {
  border: 2px solid var(--brandcolor);
  color: var(--brandcolor);
}

.pwdk-btn-alt {
  border-color: var(--light-gray);
  color: var(--light-gray);
  border: 1px solid var(--light-gray);
}

.pwdk-btn-alt:active,
.pwdk-btn-alt:hover {
  border: 2px solid var(--brandcolor);
  background-color: rgb(50, 178, 128, 0.1);
  color: var(--brandcolor);
}

.pwdk-btn-special {
  color: var(--brandcolor);
}

.pwdk-btn-special:hover,
.pwdk-btn-special:active {
  background-color: rgb(221, 221, 221, 0.2);
  color: var(--white);
}

.pwdk-btn-pill {
  border-radius: 500px;
}

.pwdk-btn-rectangle {
  border-radius: 6px;
}
/* End of general styles */

/* Small Screen */
@media (max-width: 576px) {
  .pwdk-btn-sm-mini {
    font-size: 10px;
    padding: 5px 16px;
  }

  .pwdk-btn-sm-small {
    font-size: 12px;
    padding: 6px 16px;
  }

  .pwdk-btn-sm-medium {
    font-size: 14px;
    padding: 7px 24px;
  }

  .pwdk-btn-sm-large {
    font-size: 16px;
    padding: 10px 32px;
  }

  .pwdk-btn-sm-huge {
    font-size: 18px;
    padding: 13px 32px;
  }

  .pwdk-btn-sm-primary {
    color: var(--white);
    background-color: var(--brandcolor);
    border-color: var(--brandcolor);
  }

  .pwdk-btn-sm-primary:hover {
    background-color: var(--brand-dark-1);
    border-color: var(--brand-dark-1);
  }

  .pwdk-btn-sm-primary:active {
    background-color: var(--brand-dark-3);
    border-color: var(--brand-dark-1);
  }

  .pwdk-btn-sm-disabled {
    color: var(--white);
    background-color: #c5c5c5;
    border-color: #c5c5c5;
  }

  .pwdk-btn-sm-nude {
    padding: 0 !important;
    color: var(--light-gray);
    border: none !important;
  }

  .pwdk-btn-sm-nude:hover,
  .pwdk-btn-sm-nude:active {
    color: var(--brandcolor);
  }

  .pwdk-btn-sm-ghost {
    border-color: var(--light-gray);
    color: var(--light-gray);
  }

  .pwdk-btn-sm-ghost:active,
  .pwdk-btn-sm-ghost:hover {
    border: 2px solid var(--brandcolor);
    color: var(--brandcolor);
  }

  .pwdk-btn-alt {
    border-color: var(--light-gray);
    color: var(--light-gray);
    border: 1px solid var(--light-gray);
  }

  .pwdk-btn-alt:active,
  .pwdk-btn-alt:hover {
    border: 2px solid var(--brandcolor);
    background-color: rgb(50, 178, 128, 0.1);
    color: var(--brandcolor);
  }

  .pwdk-btn-sm-special {
    color: var(--brandcolor);
  }

  .pwdk-btn-sm-special:hover,
  .pwdk-btn-sm-special:active {
    background-color: rgb(221, 221, 221, 0.2);
    color: var(--white);
  }

  .pwdk-btn-sm-pill {
    border-radius: 500px;
  }

  .pwdk-btn-md-rectangle {
    border-radius: 6px;
  }
}

/* Medium Screen */
@media (min-width: 577px) {
  .pwdk-btn-md-mini {
    font-size: 10px;
    padding: 5px 16px;
  }

  .pwdk-btn-md-small {
    font-size: 12px;
    padding: 6px 16px;
  }

  .pwdk-btn-md-medium {
    font-size: 14px;
    padding: 7px 24px;
  }

  .pwdk-btn-md-large {
    font-size: 16px;
    padding: 10px 32px;
  }

  .pwdk-btn-md-huge {
    font-size: 18px;
    padding: 13px 32px;
  }

  .pwdk-btn-md-primary {
    color: var(--white);
    background-color: var(--brandcolor);
    border-color: var(--brandcolor);
  }

  .pwdk-btn-md-primary:hover {
    background-color: var(--brand-dark-1);
    border-color: var(--brand-dark-1);
  }

  .pwdk-btn-md-primary:active {
    background-color: var(--brand-dark-3);
    border-color: var(--brand-dark-1);
  }

  .pwdk-btn-md-disabled {
    color: var(--white);
    background-color: #c5c5c5;
    border-color: #c5c5c5;
  }

  .pwdk-btn-md-nude {
    padding: 0 !important;
    color: var(--light-gray);
    border: none !important;
  }

  .pwdk-btn-md-nude:hover,
  .pwdk-btn-md-nude:active {
    color: var(--brandcolor);
  }

  .pwdk-btn-md-ghost {
    border-color: var(--light-gray);
    color: var(--light-gray);
  }

  .pwdk-btn-md-ghost:active,
  .pwdk-btn-md-ghost:hover {
    border: 2px solid var(--brandcolor);
    color: var(--brandcolor);
  }

  .pwdk-btn-alt {
    border-color: var(--light-gray);
    color: var(--light-gray);
    border: 1px solid var(--light-gray);
  }

  .pwdk-btn-alt:active,
  .pwdk-btn-alt:hover {
    border: 2px solid var(--brandcolor);
    background-color: rgb(50, 178, 128, 0.1);
    color: var(--brandcolor);
  }

  .pwdk-btn-md-special {
    color: var(--brandcolor);
  }

  .pwdk-btn-md-special:hover,
  .pwdk-btn-md-special:active {
    background-color: rgb(221, 221, 221, 0.2);
    color: var(--white);
  }

  .pwdk-btn-md-pill {
    border-radius: 500px;
  }

  .pwdk-btn-md-rectangle {
    border-radius: 6px;
  }
}
/* End of Medium Screen */
