/* Constants */

:root {
  font-family: sans-serif;
  --cool-gray: #e8e8e8;
  --rich-black: #323232;
  --brand-dark-1: #2da073;
  --brand-dark-2: #288e66;
  --brand-dark-3: #237c59;
  --brandcolor: #32b280;
  --white: #ffffff;
  --dark-gray-default: #505050;
  --dark-gray: #6e6e6e;
  --light-gray: #b3b3b3;
  --blue-compliment-2: #018de5;
  --blue-compliment-1: #0197f6;
  --blue-compliment-4: #0078c2;
  --blue-compliment-3: #0082d3;
  --red-compliment-1: #ef767a;
  --red-compliment-2: #e36367;
  --yellow-compliment-3: #dd8510;
  --yellow-compliment-4: #cc7400;
  --yellow-compliment-2: #ee9520;
  --red-compliment-4: #cc3d42;
  --yellow-compliment-1: #ffa630;
  --red-compliment-3: #d85055;
  --orange-compliment-1: #f4743b;
  --orange-compliment-2: #e36730;
  --orange-compliment-3: #d35a26;
  --orange-compliment-4: #c24d1b;
  --breakpoint-desktop: 1366px;
  --breakpoint-mobile: 576px;
}

.pwdk-form[type='text'],
.pwdk-form[type='password'],
.pwdk-form[type='number'],
.pwdk-form[type='email'],
select.pwdk-form {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  display: block;
  width: 100%;
  padding: 15px 16px 15px 16px;
  border-radius: 6px;
  border: 1px solid var(--light-gray);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: var(--dark-gray-default);
  line-height: 1.5;
  font-size: 14px;
  font-weight: 500;
  box-sizing: border-box;
  position: relative;
}

/* AUTOFILL BACKGROUND COLOR */
.pwdk-form:-webkit-autofill,
.pwdk-form:-webkit-autofill:hover,
.pwdk-form:-webkit-autofill:focus,
.pwdk-form:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f9f9f9 inset !important;
}

.pwdk-form[type='text']:focus,
.pwdk-form[type='password']:focus,
.pwdk-form[type='number']:focus,
.pwdk-form[type='email']:focus,
select.pwdk-form:focus {
  color: var(--dark-gray-default);
  background-color: var(--white);
  outline: 0;
  border-color: var(--dark-gray);
}

.pwdk-form[type='text']:valid,
.pwdk-form[type='password']:valid,
.pwdk-form[type='number']:valid,
.pwdk-form[type='email']:valid,
select.pwdk-form:valid {
  background-color: rgba(179, 179, 179, 0.08);
}

.pwdk-form[type='text']::placeholder,
.pwdk-form[type='password']::placeholder,
.pwdk-form[type='number']::placeholder,
.pwdk-form[type='email']::placeholder,
select.pwdk-form::placeholder {
  color: var(--light-gray);
}

.pwdk-form-icon[type='text'],
.pwdk-form-icon[type='password'],
.pwdk-form-icon[type='number'],
.pwdk-form-icon[type='email'],
select.pwdk-form-icon {
  display: inline-block !important;
  padding-right: 40px;
  background: no-repeat scroll;
  background-position: calc(100% - 16px);
}
/* INCOM */
.pwdk-form-icon-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.pwdk-form-icon-container > .pwdk-form {
  padding-right: 50px;
}

.pwdk-form-icon-container > [class^='icon-'],
.pwdk-form-icon-container > [class*=' icon-'] {
  position: absolute;
  /* font-size: inherit; */
  top: 14px;
  right: 16px;
  color: var(--light-gray);
}

/* .icon-mail {
  font-size: 50px;S
} */
/* INCOM */

.pwdk-form-success {
  border-color: var(--brandcolor) !important;
}

.pwdk-form-error {
  border-color: var(--red-compliment-1) !important;
}

.pwdk-form-warning {
  border-color: var(--yellow-compliment-1) !important;
}

.pwdk-form-alt {
  border-color: var(--blue-compliment-1) !important;
  background-color: rgba(1, 151, 246, 0.05) !important;
}

.pwdk-form[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 2px;
  background: rgb(179, 179, 179, 0.3);
  outline: none;
  position: relative;
  display: inline-block;
  min-width: 180px;
}

.pwdk-form[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--cool-gray);
  cursor: pointer;
  background: var(--white);
  border-radius: 50%;
}

.pwdk-form[type='range']::-moz-range-thumb {
  width: 22px;
  height: 22px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px var(--cool-gray);
  cursor: pointer;
  background: var(--white);
  border-radius: 50%;
}

.pwdk-slider-dark {
  background: var(--dark-gray) !important;
}

.pwdk-slider-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: fit-content;
  align-items: center;
}

.pwdk-slider-container > label {
  padding: 0px 16px;
  font-size: 14px;
  font-weight: normal;
  color: var(--dark-gray);
}

.pwdk-form[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  width: 17px;
  height: 17px;
  border-radius: 4px;
  border: solid 1px var(--light-gray);
  background-color: var(--white);
  outline: none;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
}

.pwdk-form[type='checkbox']:checked {
  border: solid 1px var(--brandcolor);
  background-color: rgba(179, 179, 179, 0.08);
}

/* .pwdk-form[type='checkbox']:checked::after {
  content: '';
  position: absolute;
  color: var(--dark-gray);
  display: inline-block;
  background-image: url(./icon_check/check@3x.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  height: 17px;
  width: 17px;
} */

.pwdk-checkbox-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: fit-content;
  align-items: center;
}

.pwdk-checkbox-container > label {
  margin-left: 5px;
  color: var(--dark-gray);
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0.5px;
}

.pwdk-form[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: solid 1px var(--light-gray);
  outline: none;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  vertical-align: middle;
}

.pwdk-form[type='radio']:checked::after {
  content: '';
  height: 8px;
  width: 8px;
  border-radius: 50%;
  position: absolute;
  background-color: #666666;
}

.pwdk-textarea-container {
  position: relative;
  /* padding-right: 5px; */
  padding-bottom: 18px;
}

.pwdk-textarea-container::after {
  content: attr(data-currentchar) ' / ' attr(data-maxchar) ' Characters';
  bottom: 0;
  right: 8px;
  position: absolute;
  font-size: 11px;
  padding-top: 8px;
  color: var(--light-gray);
}

.pwdk-textarea-container.min-length::after {
  content: 'Min. ' attr(data-minchar) ' Characters';
  bottom: 0;
  right: 8px;
  position: absolute;
  font-size: 11px;
  padding-top: 8px;
  color: var(--light-gray);
}

textarea.pwdk-form {
  resize: none;
  color: var(--dark-gray);
  font-weight: 500;
  font-size: 14px;
  overflow-y: scroll;
  line-height: 1.43;
  padding: 16px 16px 13px 16px;
  outline: none;
  border-radius: 6px;
  border: 1px solid var(--light-gray);
  position: relative;
  width: 100%;
  box-sizing: border-box;
  -ms-overflow-style: none;
  background-color: white;
}

textarea.pwdk-form:focus {
  border: 1px solid var(--dark-gray);
}

textarea.pwdk-form::-webkit-scrollbar {
  display: none;
}

textarea.pwdk-form::placeholder {
  font-size: 14px;
  color: var(--light-gray);
}

textarea.pwdk-form:valid {
  background-color: rgba(179, 179, 179, 0.08);
}

.pwdk-dropdown-container {
  position: relative;
  width: 100%;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 6px;
  border: solid 1px var(--light-gray);
  background-color: rgba(179, 179, 179, 0.08);
  margin: 0;
  box-sizing: border-box;
  max-height: 48px;
  transition: max-height 0.3s ease-in-out, background 0.3s ease-in-out,
    border 0.3s ease-in-out;
  overflow: hidden;
}

.pwdk-dropdown-active {
  background: white;
  border: 1px solid var(--dark-gray);
  max-height: 200px;
}

.pwdk-dropdown-placeholder {
  position: relative;
  font-weight: 500;
  font-size: 14px;
  color: var(--dark-gray);
  padding-left: 16px;
  padding-right: 30px;
  margin-top: 15px;
}

.pwdk-dropdown-active > .pwdk-dropdown-placeholder {
  position: sticky;
  color: var(--light-gray);
}

.pwdk-dropdown-placeholder::after {
  content: '';
  top: 0;
  right: 16px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  width: 8px;
  height: 8px;
  transform: rotate(-315deg);
  position: absolute;
  cursor: pointer;
  transition: transform 0.25s ease-in-out, top 0.25s ease-in-out;
}

.pwdk-dropdown-active > .pwdk-dropdown-placeholder::after {
  content: '';
  top: 2px;
  right: 16px;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  width: 8px;
  height: 8px;
  transform: rotate(-135deg);
  position: absolute;
  cursor: pointer;
}

.pwdk-dropdown-options {
  overflow-y: scroll;
  height: 140px;
  list-style: none;
  margin-left: 0 !important;
  padding: 0px;
}

.pwdk-dropdown-options > li {
  margin-top: 2px;
  left: 0;
  padding: 10px 0px;
  border-bottom: 1px solid rgb(179, 179, 179, 0.5);
  color: var(--dark-gray);
  font-weight: 500;
  box-sizing: border-box;
  text-indent: 15px;
  width: 100%;
  height: fit-content;
}

.pwdk-dropdown-options > li:hover {
  background-color: rgba(179, 179, 179, 0.08);
  border-bottom: 1px solid white;
}

.pwdk-dropdown-options > li:last-child {
  border-bottom: 1px solid white !important;
}

/* textarea font adjustment for mobile */
@media (max-width: 576px) {
  textarea.pwdk-form::placeholder,
  textarea.pwdk-form,
  .pwdk-form[type='text'],
  .pwdk-form[type='password'],
  .pwdk-form[type='number'],
  .pwdk-form[type='email'],
  select.pwdk-form {
    font-size: 12px;
  }
}

.pwdk-stepper-primary {
  font-family: CircularStd;
  width: 32px;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  border-radius: 24px;
  background-color: var(--brandcolor);
}

.pwdk-stepper-alt {
  width: 32px;
  height: 32px;
  color: var(--brandcolor);
  line-height: 32px;
  vertical-align: middle;
  text-align: center;
  font-size: 14px;
  border-radius: 24px;
  background-color: #d5efe5;
}

hr.stepper-line {
  width: 16px;
  /* height: 2px; */
  margin-left: 6px;
  margin-right: 6px;
  border: 1px solid var(--brandcolor);
}
